<template>
  <div class="container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" @click="goBackBtn">
      <Icon class="back_icon" type="ios-arrow-back"/>
      <span class="back_txt">{{ $t('key1001806') }}</span>
    </div>
    <!--基本信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1000596') }}</h2>
        </template>
        <Row type="flex" :gutter="gutterItem">
          <Col :span="ItemCol" class="basic_item" v-for="(item, index) in masterInfoList" :key="index">
            <span class="basic_title">{{ item.name }}</span>
            <span class="basic_txt">{{ detailsData[item.paramsKey] }}</span>
          </Col>
        </Row>
      </Card>
    </div>
    <!--费用明细-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <div class="flex align-items-center">
            <h2 class="title_box mr30">{{ $t('key1002725') }}</h2>
            <local-tabButton :tabButtonList="costTypeList" @selectStatus="costTypeBtn"></local-tabButton>
            <Tooltip max-width="300" placement="top" class="ml12 cursor" transfer
              :content="$t('key1002724')">
              <Icon size="18" type="md-help-circle"/>
            </Tooltip>
          </div>
        </template>
        <div class="table_box">
          <Table
            highlight-row
            border
            max-height="650"
            :loading="tableLoading"
            :columns="deliveryOrderColumns"
            :data="deliveryOrderData">
          </Table>
          <Page
            v-if="!tableLoading"
            :total="total"
            @on-change="changePage"
            show-total
            :page-size="pageParams.pageSize"
            show-elevator
            :current="pageParams.pageNum"
            show-sizer
            @on-page-size-change="changePageSize"
            placement="top"
            :page-size-opts="pageArray">
          </Page>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {EXPENSETYPE, SUPPLIER_SETTLEMENTSTATUS} from "@/utils/enum";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: 'reconciliationBillDetails',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      ItemCol: 8,
      pageParamsStatus: false,
      deliveryOrderColumns: [],
      deliveryOrderData: [],
      queryCarrierShippingName: [],
      total: 0,
      pageParams: {
        pageNum: 1,
        pageSize: 15,
        type: 0
      },
      detailsData: {},
      masterInfoList: [
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002763'), paramsKey: 'billNo'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002736'), paramsKey: 'totalPrice'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002734'), paramsKey: 'statusTxt'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002740'), paramsKey: 'billingCycle'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002733'), paramsKey: 'billGenerationTime'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002735'), paramsKey: 'lastUpdateTime'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005625'), paramsKey: 'totalCostAmount'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005626'), paramsKey: 'subsidyCostAmount'},
        {name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005627'), paramsKey: 'logisticsCost'},
      ],
      costTypeList: [
        {selected: true, value: 0, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001193')},
        {selected: false, value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002741')},
        {selected: false, value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001197')}
      ]
    };
  },
  created() {
    this.handlerInfo();
    this.costTypeBtn(0, 0);
  },
  methods: {
    // 返回列表
    goBackBtn() {
      this.$router.push('/reconciliationManage');
    },
    // 处理基本信息的数据
    handlerInfo() {
      let v = this;
      v.axios.get(api.get_ymsSupplierBill_supplierBillDetail + v.$route.query.ymsBillId).then((response) => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let keyList = ['totalPrice', 'totalCostAmount', 'subsidyCostAmount', 'logisticsCost'];
            keyList.map((key) => {
              data[key] = v.formatCurrency(data[key]) + `（${v.currencyState}）`
            });
            let statusArr = SUPPLIER_SETTLEMENTSTATUS;
            statusArr.map((item) => {
              if (item.value === data.status) {
                data.statusTxt = item.title;
              }
            });
            let startTime = data.periodStartTime;
            let endTime = data.periodEndTime;
            if (startTime && endTime) {
              let periodStartTime = v.$uDate.dealTime(startTime);
              let periodEndTime = v.$uDate.dealTime(endTime);
              data.billingCycle = periodStartTime + ' - ' + periodEndTime;
            }
            data.billGenerationTime = data.createdTime ? v.$uDate.dealTime(data.createdTime, 'fulltime') : '';
            data.lastUpdateTime = data.updatedTime ? v.$uDate.dealTime(data.updatedTime, 'fulltime') : '';
            v.detailsData = data;
          }
        }
      });
    },
    // 获取出库单详情的数据
    getDetailsData() {
      let v = this;
      v.deliveryOrderData = [];
      v.tableLoading = true;
      let query = {
        pageNum: v.pageParams.pageNum,
        pageSize: v.pageParams.pageSize,
        ymsBillId: v.$route.query.ymsBillId,
        type: v.pageParams.type,
      };
      v.axios.get(api.supplier_get_ymsPackageDetail_api_queryBillId, {params: query}).then((response) => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.total = data.total;
          v.deliveryOrderData = data.list || [];
        }
      });
    },
    // 费用明细类型筛选
    costTypeBtn(index, value) {
      let v = this;
      let columns = [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002747'),
          key: 'costType',
          minWidth: 120,
          align: 'center',
          render(h, params) {
            let text = '';
            EXPENSETYPE.map((item) => {
              if (item.value === params.row.type) {
                text = item.title;
              }
            })
            return h('span', text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000350'),
          key: 'supplierPackageCode',
          minWidth: 160,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000780'),
          key: 'supplierSpu',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000402'),
          key: 'supplierSku',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002748'),
          key: 'quantity',
          minWidth: 130,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002749'),
          key: 'thirtyVolumes',
          minWidth: 170,
          align: 'center'
        },
        {
          title: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000405')}(${v.currencyState})`,
          key: 'originalSuggestPrice',
          align: 'center',
          minWidth: 140,
          renderHeader(h, params) {
            return h('span', `${alias1bddc4d174174d47a6dfb0437dace856.t('key1005624')}(${v.currencyState})`)
          },
          render(h, params) {
            let originalSuggestPrice = params.row.originalSuggestPrice;
            return originalSuggestPrice ? h('div', [
              h('div', v.formatCurrency(originalSuggestPrice)),
              h('div', '(' + v.currencyState + ')')
            ]) : '';
          }
        },
        {
          title: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1002728')}(${v.currencyState})`,
          key: 'schemePrice',
          minWidth: 160,
          align: 'center',
          renderHeader(h, params) {
            return h('span', `${alias1bddc4d174174d47a6dfb0437dace856.t('key1002728')}(${v.currencyState})`)
          },
          render(h, params) {
            let schemePrice = params.row.schemePrice;
            return schemePrice ? h('div', [
              h('div', v.formatCurrency(params.row.schemePrice)),
              h('div', '(' + v.currencyState + ')')
            ]) : '';
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002729'),
          key: 'totalAmount',
          minWidth: 180,
          align: 'center',
          renderHeader(h, params) {
            return h('span', `${alias1bddc4d174174d47a6dfb0437dace856.t('key1002729')}(${v.currencyState})`)
          },
          render(h, params) {
            let totalAmount = params.row.totalAmount;
            return totalAmount ? h('div', [
              h('div', v.formatCurrency(params.row.totalAmount)),
              h('div', '(' + v.currencyState + ')')
            ]) : '';
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002750'),
          key: 'supplierPickupOrderNumber',
          minWidth: 160,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002751'),
          key: 'boxedTime',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let time = params.row.boxedTime;
            time = time ? v.$uDate.dealTime(time, 'fulltime') : ''
            return h('span', time)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002752'),
          key: 'pickupTime',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let time = params.row.pickupTime;
            time = time ? v.$uDate.dealTime(time, 'fulltime') : ''
            return h('span', time)
          }
        },
        {
          title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003536')}`,
          key: 'shipedTime',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let time = params.row.shipedTime;
            time = time ? v.$uDate.dealTime(time, 'fulltime') : ''
            return h('span', time)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002706'),
          key: 'createdTime',
          align: 'center',
          minWidth: 170,
          render(h, params) {
            let time = params.row.createdTime;
            time = time ? v.$uDate.dealTime(time, 'fulltime') : ''
            return h('span', time)
          }
        },
      ];
      v.deliveryOrderColumns = [];
      if (value === 0) {
        v.deliveryOrderColumns = columns;
      } else if (value === 1 || value === 2) {
        let keyList = [
          'costType',
          'totalAmount',
          'supplierPickupOrderNumber',
          'boxedTime',
          'pickupTime',
          'createdTime'
        ];
        columns.map((item) => {
          if (keyList.includes(item.key)) {
            v.deliveryOrderColumns.push(item);
          }
        })
      }
      v.pageParams.type = value;
      v.costTypeList.map((item, idx) => {
        item.selected = index === idx;
      });
      v.pageParamsStatus = true;
    },
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getDetailsData();
        v.pageParamsStatus = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
